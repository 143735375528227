.pagination_borderColor__W53Gp{border:1px solid rgba(0,0,0,0);border-radius:5px;background-clip:padding-box,border-box;background-origin:padding-box,border-box;background-image:linear-gradient(to right, #000, #000),linear-gradient(90deg, rgb(41, 248, 255), rgb(82, 55, 255))}
@media not all and (min-width: 768px){.pdflist_list__ksXed{position:relative;padding-left:16px}.pdflist_list__ksXed::before{position:absolute;top:8px;left:0;content:"";width:6px;height:6px;border-radius:50%;background-color:#0032b3}}
/* tamil */
@font-face {
  font-family: '__Tiro_Tamil_8f9dc4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d256db1a7a3a18a6-s.woff2) format('woff2');
  unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC;
}
/* latin-ext */
@font-face {
  font-family: '__Tiro_Tamil_8f9dc4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2a9947f0f593bdb6-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Tiro_Tamil_8f9dc4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fa4e2b42122810b1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Tiro_Tamil_Fallback_8f9dc4';src: local("Times New Roman");ascent-override: 67.12%;descent-override: 21.78%;line-gap-override: 38.76%;size-adjust: 112.49%
}.__className_8f9dc4 {font-family: '__Tiro_Tamil_8f9dc4', '__Tiro_Tamil_Fallback_8f9dc4';font-weight: 400;font-style: normal
}

/* tamil */
@font-face {
  font-family: '__Tiro_Tamil_8f9dc4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d256db1a7a3a18a6-s.woff2) format('woff2');
  unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC;
}
/* latin-ext */
@font-face {
  font-family: '__Tiro_Tamil_8f9dc4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2a9947f0f593bdb6-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Tiro_Tamil_8f9dc4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fa4e2b42122810b1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Tiro_Tamil_Fallback_8f9dc4';src: local("Times New Roman");ascent-override: 67.12%;descent-override: 21.78%;line-gap-override: 38.76%;size-adjust: 112.49%
}.__className_8f9dc4 {font-family: '__Tiro_Tamil_8f9dc4', '__Tiro_Tamil_Fallback_8f9dc4';font-weight: 400;font-style: normal
}

@media not all and (min-width: 768px){.pdflist_list__K9koh{position:relative;padding-left:16px}.pdflist_list__K9koh::before{position:absolute;top:8px;left:0;content:"";width:6px;height:6px;border-radius:50%;background-image:linear-gradient(270deg, #5237FF 0%, #29F8FF 100%)}}
@media not all and (min-width: 768px){.pdflist_list__5mrsH{position:relative;padding-left:16px}.pdflist_list__5mrsH::before{position:absolute;top:8px;left:0;content:"";width:6px;height:6px;border-radius:50%;background:linear-gradient(270deg, #472AFF 0%, #C35BFF 100%)}}
@media not all and (min-width: 768px){.pdflist_list__Sa7e3{position:relative;padding-left:16px}.pdflist_list__Sa7e3::before{position:absolute;top:8px;left:0;content:"";width:6px;height:6px;border-radius:50%;background-color:#c92a3a}}
@media not all and (min-width: 768px){.pdflist_list__1a1Cs{position:relative;padding-left:16px}.pdflist_list__1a1Cs::before{position:absolute;top:8px;left:0;content:"";width:6px;height:6px;border-radius:50%;background-color:#c92a3a}}
@media not all and (min-width: 768px){.bookmagazine_list__DkdXs{position:relative;padding-left:16px}.bookmagazine_list__DkdXs::before{position:absolute;top:8px;left:0;content:"";width:6px;height:6px;border-radius:50%;background-image:linear-gradient(270deg, #5237FF 0%, #29F8FF 100%)}}
.home_textColor__y7cYC{background-image:linear-gradient(180deg, #5237FF 0%, #29F8FF 100%);-webkit-text-fill-color:rgba(0,0,0,0);background-clip:text}.home_borderColor__L7shh{color:#3ad1ff;border:1px solid rgba(0,0,0,0);background-clip:padding-box,border-box;background-origin:padding-box,border-box;background-image:linear-gradient(to right, #000, #000),linear-gradient(90deg, rgb(41, 248, 255), rgb(82, 55, 255))}
.bigTabItem_active__RPQxy{background:linear-gradient(270deg, #5237FF 0%, #29F8FF 100%)}
@media not all and (min-width: 768px){.pdf_list__tZvMn{position:relative;padding-left:16px}.pdf_list__tZvMn::before{position:absolute;top:8px;left:0;content:"";width:6px;height:6px;border-radius:50%;background-image:linear-gradient(270deg, #5237FF 0%, #29F8FF 100%)}}
